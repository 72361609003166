export const timeZoneCityToCountry = [
  {
    Andorra: 'Andorra',
    Dubai: 'United Arab Emirates',
    Kabul: 'Afghanistan',
    Tirane: 'Albania',
    Yerevan: 'Armenia',
    Casey: 'Antarctica',
    Davis: 'Antarctica',
    Mawson: 'Antarctica',
    Palmer: 'Antarctica',
    Rothera: 'Antarctica',
    Troll: 'Antarctica',
    Vostok: 'Antarctica',
    Buenos_Aires: 'Argentina',
    Cordoba: 'Argentina',
    Salta: 'Argentina',
    Jujuy: 'Argentina',
    Tucuman: 'Argentina',
    Catamarca: 'Argentina',
    La_Rioja: 'Argentina',
    San_Juan: 'Argentina',
    Mendoza: 'Argentina',
    San_Luis: 'Argentina',
    Rio_Gallegos: 'Argentina',
    Ushuaia: 'Argentina',
    Pago_Pago: 'Samoa (American)',
    Vienna: 'Austria',
    Lord_Howe: 'Australia',
    Macquarie: 'Australia',
    Hobart: 'Australia',
    Melbourne: 'Australia',
    Sydney: 'Australia',
    Broken_Hill: 'Australia',
    Brisbane: 'Australia',
    Lindeman: 'Australia',
    Adelaide: 'Australia',
    Darwin: 'Australia',
    Perth: 'Australia',
    Eucla: 'Australia',
    Baku: 'Azerbaijan',
    Barbados: 'Barbados',
    Dhaka: 'Bangladesh',
    Brussels: 'Belgium',
    Sofia: 'Bulgaria',
    Bermuda: 'Bermuda',
    Brunei: 'Brunei',
    La_Paz: 'Bolivia',
    Noronha: 'Brazil',
    Belem: 'Brazil',
    Fortaleza: 'Brazil',
    Recife: 'Brazil',
    Araguaina: 'Brazil',
    Maceio: 'Brazil',
    Bahia: 'Brazil',
    Sao_Paulo: 'Brazil',
    Campo_Grande: 'Brazil',
    Cuiaba: 'Brazil',
    Santarem: 'Brazil',
    Porto_Velho: 'Brazil',
    Boa_Vista: 'Brazil',
    Manaus: 'Brazil',
    Eirunepe: 'Brazil',
    Rio_Branco: 'Brazil',
    Thimphu: 'Bhutan',
    Minsk: 'Belarus',
    Belize: 'Belize',
    St_Johns: 'Canada',
    Halifax: 'Canada',
    Glace_Bay: 'Canada',
    Moncton: 'Canada',
    Goose_Bay: 'Canada',
    Toronto: 'Canada',
    Nipigon: 'Canada',
    Thunder_Bay: 'Canada',
    Iqaluit: 'Canada',
    Pangnirtung: 'Canada',
    Winnipeg: 'Canada',
    Rainy_River: 'Canada',
    Resolute: 'Canada',
    Rankin_Inlet: 'Canada',
    Regina: 'Canada',
    Swift_Current: 'Canada',
    Edmonton: 'Canada',
    Cambridge_Bay: 'Canada',
    Yellowknife: 'Canada',
    Inuvik: 'Canada',
    Dawson_Creek: 'Canada',
    Fort_Nelson: 'Canada',
    Whitehorse: 'Canada',
    Dawson: 'Canada',
    Vancouver: 'Canada',
    Cocos: 'Cocos (Keeling) Islands',
    Zurich: 'Switzerland',
    Abidjan: "Côte d'Ivoire",
    Rarotonga: 'Cook Islands',
    Santiago: 'Chile',
    Punta_Arenas: 'Chile',
    Easter: 'Chile',
    Shanghai: 'China',
    Urumqi: 'China',
    Bogota: 'Colombia',
    Costa_Rica: 'Costa Rica',
    Havana: 'Cuba',
    Cape_Verde: 'Cape Verde',
    Christmas: 'Christmas Island',
    Nicosia: 'Cyprus',
    Famagusta: 'Cyprus',
    Prague: 'Czech Republic',
    Berlin: 'Germany',
    Copenhagen: 'Denmark',
    Santo_Domingo: 'Dominican Republic',
    Algiers: 'Algeria',
    Guayaquil: 'Ecuador',
    Galapagos: 'Ecuador',
    Tallinn: 'Estonia',
    Cairo: 'Egypt',
    El_Aaiun: 'Western Sahara',
    Madrid: 'Spain',
    Ceuta: 'Spain',
    Canary: 'Spain',
    Helsinki: 'Finland',
    Fiji: 'Fiji',
    Stanley: 'Falkland Islands',
    Chuuk: 'Micronesia',
    Pohnpei: 'Micronesia',
    Kosrae: 'Micronesia',
    Faroe: 'Faroe Islands',
    Paris: 'France',
    London: 'Britain (UK)',
    Tbilisi: 'Georgia',
    Cayenne: 'French Guiana',
    Gibraltar: 'Gibraltar',
    Nuuk: 'Greenland',
    Danmarkshavn: 'Greenland',
    Scoresbysund: 'Greenland',
    Thule: 'Greenland',
    Athens: 'Greece',
    South_Georgia: 'South Georgia & the South Sandwich Islands',
    Guatemala: 'Guatemala',
    Guam: 'Guam',
    Bissau: 'Guinea-Bissau',
    Guyana: 'Guyana',
    Hong_Kong: 'Hong Kong',
    Tegucigalpa: 'Honduras',
    'Port-au-Prince': 'Haiti',
    Budapest: 'Hungary',
    Jakarta: 'Indonesia',
    Pontianak: 'Indonesia',
    Makassar: 'Indonesia',
    Jayapura: 'Indonesia',
    Dublin: 'Ireland',
    Jerusalem: 'Israel',
    Kolkata: 'India',
    Calcutta: 'India',
    Chagos: 'British Indian Ocean Territory',
    Baghdad: 'Iraq',
    Tehran: 'Iran',
    Reykjavik: 'Iceland',
    Rome: 'Italy',
    Jamaica: 'Jamaica',
    Amman: 'Jordan',
    Tokyo: 'Japan',
    Nairobi: 'Kenya',
    Bishkek: 'Kyrgyzstan',
    Tarawa: 'Kiribati',
    Kanton: 'Kiribati',
    Kiritimati: 'Kiribati',
    Pyongyang: 'Korea (North)',
    Seoul: 'Korea (South)',
    Almaty: 'Kazakhstan',
    Qyzylorda: 'Kazakhstan',
    Qostanay: 'Kazakhstan',
    Aqtobe: 'Kazakhstan',
    Aqtau: 'Kazakhstan',
    Atyrau: 'Kazakhstan',
    Oral: 'Kazakhstan',
    Beirut: 'Lebanon',
    Colombo: 'Sri Lanka',
    Monrovia: 'Liberia',
    Vilnius: 'Lithuania',
    Luxembourg: 'Luxembourg',
    Riga: 'Latvia',
    Tripoli: 'Libya',
    Casablanca: 'Morocco',
    Monaco: 'Monaco',
    Chisinau: 'Moldova',
    Majuro: 'Marshall Islands',
    Kwajalein: 'Marshall Islands',
    Yangon: 'Myanmar (Burma)',
    Ulaanbaatar: 'Mongolia',
    Hovd: 'Mongolia',
    Choibalsan: 'Mongolia',
    Macau: 'Macau',
    Martinique: 'Martinique',
    Malta: 'Malta',
    Mauritius: 'Mauritius',
    Maldives: 'Maldives',
    Mexico_City: 'Mexico',
    Cancun: 'Mexico',
    Merida: 'Mexico',
    Monterrey: 'Mexico',
    Matamoros: 'Mexico',
    Mazatlan: 'Mexico',
    Chihuahua: 'Mexico',
    Ojinaga: 'Mexico',
    Hermosillo: 'Mexico',
    Tijuana: 'Mexico',
    Bahia_Banderas: 'Mexico',
    Kuala_Lumpur: 'Malaysia',
    Kuching: 'Malaysia',
    Maputo: 'Mozambique',
    Windhoek: 'Namibia',
    Noumea: 'New Caledonia',
    Norfolk: 'Norfolk Island',
    Lagos: 'Nigeria',
    Managua: 'Nicaragua',
    Amsterdam: 'Netherlands',
    Oslo: 'Norway',
    Kathmandu: 'Nepal',
    Nauru: 'Nauru',
    Niue: 'Niue',
    Auckland: 'New Zealand',
    Chatham: 'New Zealand',
    Panama: 'Panama',
    Lima: 'Peru',
    Tahiti: 'French Polynesia',
    Marquesas: 'French Polynesia',
    Gambier: 'French Polynesia',
    Port_Moresby: 'Papua New Guinea',
    Bougainville: 'Papua New Guinea',
    Manila: 'Philippines',
    Karachi: 'Pakistan',
    Warsaw: 'Poland',
    Miquelon: 'St Pierre & Miquelon',
    Pitcairn: 'Pitcairn',
    Puerto_Rico: 'Puerto Rico',
    Gaza: 'Palestine',
    Hebron: 'Palestine',
    Lisbon: 'Portugal',
    Madeira: 'Portugal',
    Azores: 'Portugal',
    Palau: 'Palau',
    Asuncion: 'Paraguay',
    Qatar: 'Qatar',
    Reunion: 'Réunion',
    Bucharest: 'Romania',
    Belgrade: 'Serbia',
    Kaliningrad: 'Russia',
    Moscow: 'Russia',
    Simferopol: 'Russia',
    Kirov: 'Russia',
    Volgograd: 'Russia',
    Astrakhan: 'Russia',
    Saratov: 'Russia',
    Ulyanovsk: 'Russia',
    Samara: 'Russia',
    Yekaterinburg: 'Russia',
    Omsk: 'Russia',
    Novosibirsk: 'Russia',
    Barnaul: 'Russia',
    Tomsk: 'Russia',
    Novokuznetsk: 'Russia',
    Krasnoyarsk: 'Russia',
    Irkutsk: 'Russia',
    Chita: 'Russia',
    Yakutsk: 'Russia',
    Khandyga: 'Russia',
    Vladivostok: 'Russia',
    'Ust-Nera': 'Russia',
    Magadan: 'Russia',
    Sakhalin: 'Russia',
    Srednekolymsk: 'Russia',
    Kamchatka: 'Russia',
    Anadyr: 'Russia',
    Riyadh: 'Saudi Arabia',
    Guadalcanal: 'Solomon Islands',
    Mahe: 'Seychelles',
    Khartoum: 'Sudan',
    Stockholm: 'Sweden',
    Singapore: 'Singapore',
    Paramaribo: 'Suriname',
    Juba: 'South Sudan',
    Sao_Tome: 'Sao Tome & Principe',
    El_Salvador: 'El Salvador',
    Damascus: 'Syria',
    Grand_Turk: 'Turks & Caicos Is',
    Ndjamena: 'Chad',
    Kerguelen: 'French Southern & Antarctic Lands',
    Bangkok: 'Thailand',
    Dushanbe: 'Tajikistan',
    Fakaofo: 'Tokelau',
    Dili: 'East Timor',
    Ashgabat: 'Turkmenistan',
    Tunis: 'Tunisia',
    Tongatapu: 'Tonga',
    Istanbul: 'Turkey',
    Funafuti: 'Tuvalu',
    Taipei: 'Taiwan',
    Kiev: 'Ukraine',
    Uzhgorod: 'Ukraine',
    Zaporozhye: 'Ukraine',
    Wake: 'US minor outlying islands',
    New_York: 'United States',
    Detroit: 'United States',
    Louisville: 'United States',
    Monticello: 'United States',
    Indianapolis: 'United States',
    Vincennes: 'United States',
    Winamac: 'United States',
    Marengo: 'United States',
    Petersburg: 'United States',
    Vevay: 'United States',
    Chicago: 'United States',
    Tell_City: 'United States',
    Knox: 'United States',
    Menominee: 'United States',
    Center: 'United States',
    New_Salem: 'United States',
    Beulah: 'United States',
    Denver: 'United States',
    Boise: 'United States',
    Phoenix: 'United States',
    Los_Angeles: 'United States',
    Anchorage: 'United States',
    Juneau: 'United States',
    Sitka: 'United States',
    Metlakatla: 'United States',
    Yakutat: 'United States',
    Nome: 'United States',
    Adak: 'United States',
    Honolulu: 'United States',
    Montevideo: 'Uruguay',
    Samarkand: 'Uzbekistan',
    Tashkent: 'Uzbekistan',
    Caracas: 'Venezuela',
    Ho_Chi_Minh: 'Vietnam',
    Efate: 'Vanuatu',
    Wallis: 'Wallis & Futuna',
    Apia: 'Samoa (western)',
    Johannesburg: 'South Africa',
    Antigua: 'Antigua & Barbuda',
    Anguilla: 'Anguilla',
    Luanda: 'Angola',
    McMurdo: 'Antarctica',
    DumontDUrville: 'Antarctica',
    Syowa: 'Antarctica',
    Aruba: 'Aruba',
    Mariehamn: 'Åland Islands',
    Sarajevo: 'Bosnia & Herzegovina',
    Ouagadougou: 'Burkina Faso',
    Bahrain: 'Bahrain',
    Bujumbura: 'Burundi',
    'Porto-Novo': 'Benin',
    St_Barthelemy: 'St Barthelemy',
    Kralendijk: 'Caribbean NL',
    Nassau: 'Bahamas',
    Gaborone: 'Botswana',
    'Blanc-Sablon': 'Canada',
    Atikokan: 'Canada',
    Creston: 'Canada',
    Kinshasa: 'Congo (Dem. Rep.)',
    Lubumbashi: 'Congo (Dem. Rep.)',
    Bangui: 'Central African Rep.',
    Brazzaville: 'Congo (Rep.)',
    Douala: 'Cameroon',
    Curacao: 'Curaçao',
    Busingen: 'Germany',
    Djibouti: 'Djibouti',
    Dominica: 'Dominica',
    Asmara: 'Eritrea',
    Addis_Ababa: 'Ethiopia',
    Libreville: 'Gabon',
    Grenada: 'Grenada',
    Guernsey: 'Guernsey',
    Accra: 'Ghana',
    Banjul: 'Gambia',
    Conakry: 'Guinea',
    Guadeloupe: 'Guadeloupe',
    Malabo: 'Equatorial Guinea',
    Zagreb: 'Croatia',
    Isle_of_Man: 'Isle of Man',
    Jersey: 'Jersey',
    Phnom_Penh: 'Cambodia',
    Comoro: 'Comoros',
    St_Kitts: 'St Kitts & Nevis',
    Kuwait: 'Kuwait',
    Cayman: 'Cayman Islands',
    Vientiane: 'Laos',
    St_Lucia: 'St Lucia',
    Vaduz: 'Liechtenstein',
    Maseru: 'Lesotho',
    Podgorica: 'Montenegro',
    Marigot: 'St Martin (French)',
    Antananarivo: 'Madagascar',
    Skopje: 'North Macedonia',
    Bamako: 'Mali',
    Saipan: 'Northern Mariana Islands',
    Nouakchott: 'Mauritania',
    Montserrat: 'Montserrat',
    Blantyre: 'Malawi',
    Niamey: 'Niger',
    Muscat: 'Oman',
    Kigali: 'Rwanda',
    St_Helena: 'St Helena',
    Ljubljana: 'Slovenia',
    Longyearbyen: 'Svalbard & Jan Mayen',
    Bratislava: 'Slovakia',
    Freetown: 'Sierra Leone',
    San_Marino: 'San Marino',
    Dakar: 'Senegal',
    Mogadishu: 'Somalia',
    Lower_Princes: 'St Maarten (Dutch)',
    Mbabane: 'Eswatini (Swaziland)',
    Lome: 'Togo',
    Port_of_Spain: 'Trinidad & Tobago',
    Dar_es_Salaam: 'Tanzania',
    Kampala: 'Uganda',
    Midway: 'US minor outlying islands',
    Vatican: 'Vatican City',
    St_Vincent: 'St Vincent',
    Tortola: 'Virgin Islands (UK)',
    St_Thomas: 'Virgin Islands (US)',
    Aden: 'Yemen',
    Mayotte: 'Mayotte',
    Lusaka: 'Zambia',
    Harare: 'Zimbabwe',
  },
];

export const timeZoneCityToCountryCode = [
  {
    Andorra: 'AD',
    Dubai: 'AE',
    Kabul: 'AF',
    Tirane: 'AL',
    Yerevan: 'AM',
    Casey: 'AQ',
    Davis: 'AQ',
    Mawson: 'AQ',
    Palmer: 'AQ',
    Rothera: 'AQ',
    Troll: 'AQ',
    Vostok: 'AQ',
    Buenos_Aires: 'AR',
    Cordoba: 'AR',
    Salta: 'AR',
    Jujuy: 'AR',
    Tucuman: 'AR',
    Catamarca: 'AR',
    La_Rioja: 'AR',
    San_Juan: 'AR',
    Mendoza: 'AR',
    San_Luis: 'AR',
    Rio_Gallegos: 'AR',
    Ushuaia: 'AR',
    Pago_Pago: 'AS',
    Vienna: 'AT',
    Lord_Howe: 'AU',
    Macquarie: 'AU',
    Hobart: 'AU',
    Melbourne: 'AU',
    Sydney: 'AU',
    Broken_Hill: 'AU',
    Brisbane: 'AU',
    Lindeman: 'AU',
    Adelaide: 'AU',
    Darwin: 'AU',
    Perth: 'AU',
    Eucla: 'AU',
    Baku: 'AZ',
    Barbados: 'BB',
    Dhaka: 'BD',
    Brussels: 'BE',
    Sofia: 'BG',
    Bermuda: 'BM',
    Brunei: 'BN',
    La_Paz: 'BO',
    Noronha: 'BR',
    Belem: 'BR',
    Fortaleza: 'BR',
    Recife: 'BR',
    Araguaina: 'BR',
    Maceio: 'BR',
    Bahia: 'BR',
    Sao_Paulo: 'BR',
    Campo_Grande: 'BR',
    Cuiaba: 'BR',
    Santarem: 'BR',
    Porto_Velho: 'BR',
    Boa_Vista: 'BR',
    Manaus: 'BR',
    Eirunepe: 'BR',
    Rio_Branco: 'BR',
    Thimphu: 'BT',
    Minsk: 'BY',
    Belize: 'BZ',
    St_Johns: 'CA',
    Halifax: 'CA',
    Glace_Bay: 'CA',
    Moncton: 'CA',
    Goose_Bay: 'CA',
    Toronto: 'CA',
    Nipigon: 'CA',
    Thunder_Bay: 'CA',
    Iqaluit: 'CA',
    Pangnirtung: 'CA',
    Winnipeg: 'CA',
    Rainy_River: 'CA',
    Resolute: 'CA',
    Rankin_Inlet: 'CA',
    Regina: 'CA',
    Swift_Current: 'CA',
    Edmonton: 'CA',
    Cambridge_Bay: 'CA',
    Yellowknife: 'CA',
    Inuvik: 'CA',
    Dawson_Creek: 'CA',
    Fort_Nelson: 'CA',
    Whitehorse: 'CA',
    Dawson: 'CA',
    Vancouver: 'CA',
    Cocos: 'CC',
    Zurich: 'CH',
    Abidjan: 'CI',
    Rarotonga: 'CK',
    Santiago: 'CL',
    Punta_Arenas: 'CL',
    Easter: 'CL',
    Shanghai: 'CN',
    Urumqi: 'CN',
    Bogota: 'CO',
    Costa_Rica: 'CR',
    Havana: 'CU',
    Cape_Verde: 'CV',
    Christmas: 'CX',
    Nicosia: 'CY',
    Famagusta: 'CY',
    Prague: 'CZ',
    Berlin: 'DE',
    Copenhagen: 'DK',
    Santo_Domingo: 'DO',
    Algiers: 'DZ',
    Guayaquil: 'EC',
    Galapagos: 'EC',
    Tallinn: 'EE',
    Cairo: 'EG',
    El_Aaiun: 'EH',
    Madrid: 'ES',
    Ceuta: 'ES',
    Canary: 'ES',
    Helsinki: 'FI',
    Fiji: 'FJ',
    Stanley: 'FK',
    Chuuk: 'FM',
    Pohnpei: 'FM',
    Kosrae: 'FM',
    Faroe: 'FO',
    Paris: 'FR',
    London: 'GB',
    Tbilisi: 'GE',
    Cayenne: 'GF',
    Gibraltar: 'GI',
    Nuuk: 'GL',
    Danmarkshavn: 'GL',
    Scoresbysund: 'GL',
    Thule: 'GL',
    Athens: 'GR',
    South_Georgia: 'GS',
    Guatemala: 'GT',
    Guam: 'GU',
    Bissau: 'GW',
    Guyana: 'GY',
    Hong_Kong: 'HK',
    Tegucigalpa: 'HN',
    'Port-au-Prince': 'HT',
    Budapest: 'HU',
    Jakarta: 'ID',
    Pontianak: 'ID',
    Makassar: 'ID',
    Jayapura: 'ID',
    Dublin: 'IE',
    Jerusalem: 'IL',
    Kolkata: 'IN',
    Calcutta: 'IN',
    Chagos: 'IO',
    Baghdad: 'IQ',
    Tehran: 'IR',
    Reykjavik: 'IS',
    Rome: 'IT',
    Jamaica: 'JM',
    Amman: 'JO',
    Tokyo: 'JP',
    Nairobi: 'KE',
    Bishkek: 'KG',
    Tarawa: 'KI',
    Kanton: 'KI',
    Kiritimati: 'KI',
    Pyongyang: 'KP',
    Seoul: 'KR',
    Almaty: 'KZ',
    Qyzylorda: 'KZ',
    Qostanay: 'KZ',
    Aqtobe: 'KZ',
    Aqtau: 'KZ',
    Atyrau: 'KZ',
    Oral: 'KZ',
    Beirut: 'LB',
    Colombo: 'LK',
    Monrovia: 'LR',
    Vilnius: 'LT',
    Luxembourg: 'LU',
    Riga: 'LV',
    Tripoli: 'LY',
    Casablanca: 'MA',
    Monaco: 'MC',
    Chisinau: 'MD',
    Majuro: 'MH',
    Kwajalein: 'MH',
    Yangon: 'MM',
    Ulaanbaatar: 'MN',
    Hovd: 'MN',
    Choibalsan: 'MN',
    Macau: 'MO',
    Martinique: 'MQ',
    Malta: 'MT',
    Mauritius: 'MU',
    Maldives: 'MV',
    Mexico_City: 'MX',
    Cancun: 'MX',
    Merida: 'MX',
    Monterrey: 'MX',
    Matamoros: 'MX',
    Mazatlan: 'MX',
    Chihuahua: 'MX',
    Ojinaga: 'MX',
    Hermosillo: 'MX',
    Tijuana: 'MX',
    Bahia_Banderas: 'MX',
    Kuala_Lumpur: 'MY',
    Kuching: 'MY',
    Maputo: 'MZ',
    Windhoek: 'NA',
    Noumea: 'NC',
    Norfolk: 'NF',
    Lagos: 'NG',
    Managua: 'NI',
    Amsterdam: 'NL',
    Oslo: 'NO',
    Kathmandu: 'NP',
    Nauru: 'NR',
    Niue: 'NU',
    Auckland: 'NZ',
    Chatham: 'NZ',
    Panama: 'PA',
    Lima: 'PE',
    Tahiti: 'PF',
    Marquesas: 'PF',
    Gambier: 'PF',
    Port_Moresby: 'PG',
    Bougainville: 'PG',
    Manila: 'PH',
    Karachi: 'PK',
    Warsaw: 'PL',
    Miquelon: 'PM',
    Pitcairn: 'PN',
    Puerto_Rico: 'PR',
    Gaza: 'PS',
    Hebron: 'PS',
    Lisbon: 'PT',
    Madeira: 'PT',
    Azores: 'PT',
    Palau: 'PW',
    Asuncion: 'PY',
    Qatar: 'QA',
    Reunion: 'RE',
    Bucharest: 'RO',
    Belgrade: 'RS',
    Kaliningrad: 'RU',
    Moscow: 'RU',
    Simferopol: 'RU',
    Kirov: 'RU',
    Volgograd: 'RU',
    Astrakhan: 'RU',
    Saratov: 'RU',
    Ulyanovsk: 'RU',
    Samara: 'RU',
    Yekaterinburg: 'RU',
    Omsk: 'RU',
    Novosibirsk: 'RU',
    Barnaul: 'RU',
    Tomsk: 'RU',
    Novokuznetsk: 'RU',
    Krasnoyarsk: 'RU',
    Irkutsk: 'RU',
    Chita: 'RU',
    Yakutsk: 'RU',
    Khandyga: 'RU',
    Vladivostok: 'RU',
    'Ust-Nera': 'RU',
    Magadan: 'RU',
    Sakhalin: 'RU',
    Srednekolymsk: 'RU',
    Kamchatka: 'RU',
    Anadyr: 'RU',
    Riyadh: 'SA',
    Guadalcanal: 'SB',
    Mahe: 'SC',
    Khartoum: 'SD',
    Stockholm: 'SE',
    Singapore: 'SG',
    Paramaribo: 'SR',
    Juba: 'SS',
    Sao_Tome: 'ST',
    El_Salvador: 'SV',
    Damascus: 'SY',
    Grand_Turk: 'TC',
    Ndjamena: 'TD',
    Kerguelen: 'TF',
    Bangkok: 'TH',
    Dushanbe: 'TJ',
    Fakaofo: 'TK',
    Dili: 'TL',
    Ashgabat: 'TM',
    Tunis: 'TN',
    Tongatapu: 'TO',
    Istanbul: 'TR',
    Funafuti: 'TV',
    Taipei: 'TW',
    Kiev: 'UA',
    Uzhgorod: 'UA',
    Zaporozhye: 'UA',
    Wake: 'UM',
    New_York: 'US',
    Detroit: 'US',
    Louisville: 'US',
    Monticello: 'US',
    Indianapolis: 'US',
    Vincennes: 'US',
    Winamac: 'US',
    Marengo: 'US',
    Petersburg: 'US',
    Vevay: 'US',
    Chicago: 'US',
    Tell_City: 'US',
    Knox: 'US',
    Menominee: 'US',
    Center: 'US',
    New_Salem: 'US',
    Beulah: 'US',
    Denver: 'US',
    Boise: 'US',
    Phoenix: 'US',
    Los_Angeles: 'US',
    Anchorage: 'US',
    Juneau: 'US',
    Sitka: 'US',
    Metlakatla: 'US',
    Yakutat: 'US',
    Nome: 'US',
    Adak: 'US',
    Honolulu: 'US',
    Montevideo: 'UY',
    Samarkand: 'UZ',
    Tashkent: 'UZ',
    Caracas: 'VE',
    Ho_Chi_Minh: 'VN',
    Efate: 'VU',
    Wallis: 'WF',
    Apia: 'WS',
    Johannesburg: 'ZA',
    Antigua: 'AG',
    Anguilla: 'AI',
    Luanda: 'AO',
    McMurdo: 'AQ',
    DumontDUrville: 'AQ',
    Syowa: 'AQ',
    Aruba: 'AW',
    Mariehamn: 'AX',
    Sarajevo: 'BA',
    Ouagadougou: 'BF',
    Bahrain: 'BH',
    Bujumbura: 'BI',
    'Porto-Novo': 'BJ',
    St_Barthelemy: 'BL',
    Kralendijk: 'BQ',
    Nassau: 'BS',
    Gaborone: 'BW',
    'Blanc-Sablon': 'CA',
    Atikokan: 'CA',
    Creston: 'CA',
    Kinshasa: 'CD',
    Lubumbashi: 'CD',
    Bangui: 'CF',
    Brazzaville: 'CG',
    Douala: 'CM',
    Curacao: 'CW',
    Busingen: 'DE',
    Djibouti: 'DJ',
    Dominica: 'DM',
    Asmara: 'ER',
    Addis_Ababa: 'ET',
    Libreville: 'GA',
    Grenada: 'GD',
    Guernsey: 'GG',
    Accra: 'GH',
    Banjul: 'GM',
    Conakry: 'GN',
    Guadeloupe: 'GP',
    Malabo: 'GQ',
    Zagreb: 'HR',
    Isle_of_Man: 'IM',
    Jersey: 'JE',
    Phnom_Penh: 'KH',
    Comoro: 'KM',
    St_Kitts: 'KN',
    Kuwait: 'KW',
    Cayman: 'KY',
    Vientiane: 'LA',
    St_Lucia: 'LC',
    Vaduz: 'LI',
    Maseru: 'LS',
    Podgorica: 'ME',
    Marigot: 'MF',
    Antananarivo: 'MG',
    Skopje: 'MK',
    Bamako: 'ML',
    Saipan: 'MP',
    Nouakchott: 'MR',
    Montserrat: 'MS',
    Blantyre: 'MW',
    Niamey: 'NE',
    Muscat: 'OM',
    Kigali: 'RW',
    St_Helena: 'SH',
    Ljubljana: 'SI',
    Longyearbyen: 'SJ',
    Bratislava: 'SK',
    Freetown: 'SL',
    San_Marino: 'SM',
    Dakar: 'SN',
    Mogadishu: 'SO',
    Lower_Princes: 'SX',
    Mbabane: 'SZ',
    Lome: 'TG',
    Port_of_Spain: 'TT',
    Dar_es_Salaam: 'TZ',
    Kampala: 'UG',
    Midway: 'UM',
    Vatican: 'VA',
    St_Vincent: 'VC',
    Tortola: 'VG',
    St_Thomas: 'VI',
    Aden: 'YE',
    Mayotte: 'YT',
    Lusaka: 'ZM',
    Harare: 'ZW',
  },
];
