import { yupResolver } from '@hookform/resolvers/yup';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import Container from 'app/components/common/Container';
import GlobalLocation from 'app/components/common/GlobalLocation';
import MailOutlineIcon from 'app/components/icons/MailOutlineIcon';
import PhoneCallOutlineIcon from 'app/components/icons/PhoneCallOutlineIcon';
import { ALL_SUBMIT_FORM, GLOBAL_LOCATION } from 'app/helpers/apiEndpoint';
import { getTrackingData } from 'app/webtrack/tracking';
import REQUEST from 'app/helpers/http.service';
import { timeZoneCityToCountry } from 'app/helpers/timeZoneCityToCountry';
import { getUserLocationData, getLocaleIP } from 'app/scripts/utils';

import staticData from '../../public/staticData.json';
import CloudinaryImage from '../components/common/CloudinaryImage';
import './footer.css';

const style = {
  socialLinks: {
    width: '45px',
    height: '40px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    height: '45px',
    width: '100%',
    borderRadius: '4px 0px 0px 4px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    color: '#000000',
  },
  subButton: {
    height: '45px',
    borderRadius: '0px 4px 4px 0px !important',
  },
  checkbox: {
    height: '22px',
    width: '22px',
    boxShadow: 'none',
  },
};

export default function Footer() {
  const footerData = staticData?.footer?.attributes;
  const [showLocations, setShowLocations] = useState(false);
  const [active, setActive] = useState(false);
  const [isDefaultChecked, setIsDefaultChecked] = useState(true);
  const [locationip, setLocationIP] = useState(false);
  const handleCheckboxChange = e => {
    setIsDefaultChecked(e.target.checked);
  };
  const { asPath } = useRouter();

  const locationData = getUserLocationData(timeZoneCityToCountry);

  const fetchIP = async () => {
    const { ip } = await getLocaleIP();
    setLocationIP(ip);
  };
  fetchIP();

  // .matches(
  //   /^(?!.*@(?:gmail|outlook|yahoo|protonmail|zoho|aol)\.\w{2,}$)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  //   'Work email addresses are allowed'
  // ),
  const Schema = yup.object().shape({
    countryName: yup.string().required(),
    email: yup
      .string()
      .email('Invalid email format')
      .required('Email is required'),
    purpose: yup.string().required('Type is required'),
    subscribeCheckbox: yup
      .boolean()
      .oneOf([true], 'Please accept the Privacy Policy'),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      email: '',
      countryName: locationData.userCountry,
      purpose: 'SUBSCRIBE',
      ctaText: 'SUBSCRIBE',
      pageUrl: asPath,
      leadSource: 'Footer',
      subscribeCheckbox: isDefaultChecked,
    },
  });
  const toast = useSelector(state => state?.toast);
  const SubmitHandler = async data => {
    try {
      const trackingData = getTrackingData();

      // Ensure WebPagesVisited is a proper string
      const cleanedTrackingData = {
        ...trackingData,
        WebPagesVisited: Array.isArray(trackingData?.WebPagesVisited)
          ? trackingData.WebPagesVisited.join(', ') // Convert array to comma-separated string
          : trackingData?.WebPagesVisited || '', // Ensure it's a string
      };
      const formData = {
        ...data,
        ipAddress: locationip,
        trackingData: cleanedTrackingData, // Use cleaned tracking data
      };
      setActive(!active);
      const res = await REQUEST({
        method: 'POST',
        url: ALL_SUBMIT_FORM,
        data: { data: formData },
      });
      if (res?.status === 200) {
        toast.success('Submitted Successfully');
        reset();
        setActive(active);
      } else toast.error(res?.data?.error?.message);
    } catch (err) {
      toast.error('failed');
    }
  };

  const linkData = useMemo(() => {
    return [
      {
        header: footerData?.footer_section2?.title,
        children: footerData?.footer_section2?.footer_link?.map(
          (data, key) => ({
            key: key,
            label: data?.name,
            href: data?.href || '#',
          })
        ),
      },
      {
        header: footerData?.footer_section3?.title,
        children: footerData?.footer_section3?.footer_services?.map(
          (data, key) => ({
            key: key,
            label: data?.name,
            href: data?.href || '#',
          })
        ),
      },
      {
        header: footerData?.footer_section6?.title,
        children: footerData?.footer_section6?.footer_link?.map(
          (data, key) => ({
            key: key,
            label: data?.name,
            href: data?.href || '#',
          })
        ),
      },
      {
        header: footerData?.footer_section4?.title,
        groups: [
          {
            icon: <MailOutlineIcon />,
            children: [
              {
                label: footerData?.footer_section4?.email1,
                href: `mailto:${footerData?.footer_section4?.email1}`,
              },
              {
                label: footerData?.footer_section4?.email2,
                href: `mailto:${footerData?.footer_section4?.email2}`,
              },
              {
                label: footerData?.footer_section4?.email3,
                href: `mailto:${footerData?.footer_section4?.email3}`,
              },
              {
                label: footerData?.footer_section4?.email4,
                href: `mailto:${footerData?.footer_section4?.email4}`,
              },
            ],
          },
          {
            icon: <PhoneCallOutlineIcon />,
            children: [
              {
                label: footerData?.footer_section4?.phone_no1,
                href: `tel:${footerData?.footer_section4?.phone_no1.replace(/[^\d+]/g, '')}`,
              },
              {
                label: footerData?.footer_section4?.phone_no2,
                href: `tel:${footerData?.footer_section4?.phone_no2.replace(/[^\d+]/g, '')}`,
              },
              {
                label: footerData?.footer_section4?.phone_n03,
                href: `tel:${footerData?.footer_section4?.phone_n03.replace(/[^\d+]/g, '')}`,
              },
              {
                label: footerData?.footer_section4?.phone_no4,
                href: `tel:${footerData?.footer_section4?.phone_no4.replace(/[^\d+]/g, '')}`,
              },
            ],
          },
        ],
      },
    ];
  }, [footerData]);

  const [location, setLocation] = useState();

  const getLocation = async () => {
    try {
      const companyLocation = await REQUEST({
        method: 'GET',
        url: GLOBAL_LOCATION,
      });
      setLocation(companyLocation?.data?.data?.attributes);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  return (
    <>
      <GlobalLocation global_location={location} />
      <footer className="relative z-40 bg-footer-texture-new bg-cover bg-center print-delete bg-[url(https://res.cloudinary.com/dthpnue1d/image/upload/c_fill,g_faces,w_991/v1691470450/footer_texture_dd354ddcbc.png)] bg-[#13131A]">
        <Container className="py-10 text-white">
          <div className="md:grid gap-8 grid-cols-12 text-sm">
            <div className="xl:col-span-9 md:col-span-12">
              <div className="sm:grid md:grid-cols-2 lg:grid-cols-4 gap-6">
                {linkData.map(item => (
                  <div className="mb-6 lg:mb-0 " key={item?.id}>
                    <h6 className="mb-7 text-[24px] font-[700] text-[#F2F5F6]">
                      {item.header}
                    </h6>
                    {item.header === 'Get in Touch' && (
                      <Link
                        className="py-2 px-5 inline-flex text-[#000D3A] bg-[#0050D5] text-[16px] font-[500] border border-transparent hover:bg-[#0050D5] text-center rounded-[4px] text-[#F2F5F6]"
                        style={{ marginBottom: '20px' }}
                        href={`${footerData?.footer_section4?.href}`}
                      >
                        <span className="mr-2">
                          {footerData?.footer_section4?.button_text}
                        </span>
                        <svg
                          className="font-[14px] w-[14px]"
                          viewBox="0 0 448 512"
                        >
                          <path
                            fill="currentColor"
                            d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
                          ></path>
                        </svg>
                      </Link>
                    )}
                    <div className={`${item.groups ? 'flex-col' : 'flex'}`}>
                      {!!item.groups &&
                        item.groups.map(subitem => (
                          <div className="flex" key={subitem?.id}>
                            {!!subitem.icon && subitem.icon}
                            <ul className="font-[400] mb-2 ml-2 flex flex-col gap-3 text-[14px]">
                              {subitem.children.map(link => (
                                <li key={link?.id}>
                                  <Link
                                    href={link.href}
                                    className="flex items-center"
                                  >
                                    <span
                                      className={`${
                                        link.label === '+1 (860) 247-1400'
                                          ? 'us flag mr-1'
                                          : link.label === '+91 (124) 426-0818'
                                            ? 'india flag mr-1'
                                            : link.label ===
                                                '+62 (811) 151-9851'
                                              ? 'indonasia flag mr-1'
                                              : link.label === '+1 289-633-4046'
                                                ? 'canada flag mr-1'
                                                : ''
                                      }`}
                                    ></span>{' '}
                                    {link.label}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      {!!item.children && (
                        <ul className="font-[400] mb-2 flex flex-col gap-3 text-[16px]">
                          {item.children.map((link, key) =>
                            link?.label !== 'Location Based Services' ? (
                              <li key={key}>
                                {link?.href?.length > 0 ? (
                                  <Link
                                    href={link.href}
                                    className="flex items-center text-[#F2F5F6]"
                                  >
                                    {!!link.icon && link.icon}
                                    <span
                                      className={link.icon ? 'ml-2' : 'flex'}
                                    >
                                      {link.label}
                                    </span>
                                  </Link>
                                ) : (
                                  <span className="flex items-center">
                                    {!!link.icon && link.icon}
                                    <span
                                      className={link.icon ? 'ml-2' : 'flex'}
                                    >
                                      {link.label}
                                    </span>
                                  </span>
                                )}
                              </li>
                            ) : (
                              <li key={key}>
                                <div
                                  className="flex items-center"
                                  onClick={() => {
                                    setShowLocations(!showLocations);
                                  }}
                                >
                                  <span>{link.label}</span>
                                  <Image
                                    className={'ml-2'}
                                    src="/icons/white-arrow-down.svg"
                                    alt="layout-image"
                                    width={10}
                                    height={10}
                                  />
                                </div>
                                {showLocations && (
                                  <>
                                    <li className="mt-2" key={key}>
                                      <Link
                                        href={
                                          '/locations/it-service-in-massachusetts'
                                        }
                                        className="flex items-center"
                                      >
                                        <span
                                          className={
                                            link.icon ? 'ml-2' : 'ml-0'
                                          }
                                        >
                                          IT Staffing Services in Massachusetts
                                        </span>
                                      </Link>
                                    </li>
                                    <li className="mt-2">
                                      <Link
                                        href={
                                          '/locations/it-service-in-connecticut'
                                        }
                                        className="flex items-center"
                                      >
                                        <span
                                          className={
                                            link.icon ? 'ml-2' : 'ml-0'
                                          }
                                        >
                                          IT Staffing Services in Connecticut
                                        </span>
                                      </Link>
                                    </li>
                                    <li className="mt-2">
                                      <Link
                                        href={
                                          '/locations/data-analytics-engineering-in-massachusetts'
                                        }
                                        className="flex items-center"
                                      >
                                        <span
                                          className={
                                            link.icon ? 'ml-2' : 'ml-0'
                                          }
                                        >
                                          Data Analytics Engineering in
                                          Massachusetts
                                        </span>
                                      </Link>
                                    </li>
                                    <li className="mt-2">
                                      <Link
                                        href={
                                          '/locations/web-development-company-in-massachusetts'
                                        }
                                        className="flex items-center"
                                      >
                                        <span
                                          className={
                                            link.icon ? 'ml-2' : 'ml-0'
                                          }
                                        >
                                          Web Development Company in
                                          Massachusetts
                                        </span>
                                      </Link>
                                    </li>
                                  </>
                                )}
                              </li>
                            )
                          )}
                        </ul>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="xl:col-span-3 md:col-span-6">
              <div className="">
                <p className="text-[16px] font-[500] text-[#F2F5F6]">
                  Get insights from experts on building & Scaling technology
                  teams
                </p>
                <form
                  className="space-y-3"
                  onSubmit={handleSubmit(SubmitHandler)}
                >
                  <div className="flex mt-6 mb-2">
                    <input
                      type="text"
                      placeholder="Email"
                      {...register('email')}
                      name="email"
                      className="focus:ring-0 focus:border-[#fff]"
                      style={style.input}
                    />
                    <button
                      id="Subscribe"
                      type="submit"
                      disabled={active}
                      style={style.subButton}
                      className="bg-[#0050D5]"
                    >
                      <span className="flex items-center rounded-md text-[16px] font-[500] px-4 text-[#F2F5F6]">
                        SUBSCRIBE
                      </span>
                    </button>
                  </div>
                  {errors.email && (
                    <span className="mt-2 font-semibold text-sm text-red-700">
                      {errors.email?.message}
                    </span>
                  )}
                  <input
                    type="text"
                    placeholder="countryName"
                    {...register('countryName')}
                    name="countryName"
                    className="hidden"
                  />
                  <input
                    type="text"
                    placeholder="pageUrl"
                    {...register('pageUrl')}
                    name="pageUrl"
                    className="hidden"
                  />
                  <div className="mb-[30px_!important] mt-[10px_!important]">
                    <label className="flex items-center space-x-1">
                      <Controller
                        name="subscribeCheckbox"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="checkbox"
                            className="form-checkbox rounded-full text-[#fff] bg-[#fff] border-[#fff]"
                            {...field}
                            onChange={e => {
                              field.onChange(e);
                              handleCheckboxChange(e);
                            }}
                            defaultChecked={isDefaultChecked}
                          />
                        )}
                      />
                      <span className="text-[#F2F5F6] text-[12px]  font-[400]">
                        By subscribing I accept the
                        <Link
                          href="/privacy-policy"
                          className="underline ml-2 inline-block"
                        >
                          Privacy Policy
                        </Link>
                      </span>
                    </label>
                    {errors.subscribeCheckbox && (
                      <span className="mt-2 font-semibold text-sm text-red-700">
                        {errors?.subscribeCheckbox?.message}
                      </span>
                    )}
                  </div>
                </form>
                <ul className="mt-2 flex flex-col gap-4">
                  <li className="flex gap-4">
                    {footerData?.social_media?.map(data => (
                      <Link
                        href={data?.link || '#'}
                        target="_blank"
                        className="bg-white group hover:bg-[#0050d5] transition duration-300 ease-linear hover:ease-linear hover:duration-300"
                        style={style.socialLinks}
                        key={data?.id}
                      >
                        <div className="flex justify-center items-center rounded-full h-6 w-6">
                          <span className="hidden">LinkedIn</span>
                          <CloudinaryImage
                            backendImgUrl={
                              data?.logo_image?.data?.attributes?.url
                            }
                            alt={
                              data?.logo_image?.data?.attributes
                                ?.alternativeText
                            }
                            className="w-[20px] h-[20px] group-hover:invert ease-[cubic-bezier(0.95,0.05,0.795,0.035)]"
                            type="icon"
                          />
                        </div>
                      </Link>
                    ))}
                  </li>
                </ul>
                <div className="footergptwlogo mt-3 flex  items-center">
                  {footerData?.footer_section5?.awardImg?.map((val, ind) => (
                    <CloudinaryImage
                      key={ind}
                      backendImgUrl={val?.image?.data?.attributes?.url}
                      className="w-16 inline-block"
                      alt="award image"
                      type="icon"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <hr className="border-gray-600 my-8"></hr>
          <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row items-start lg:items-center justify-between font-light text-sm">
            <p className="w-full sm:w-1/3 lg:w-fit ">© All rights reserved</p>
            <ul className="flex flex-row gap-2 lg:gap-4 mr-20">
              <li className="cursor-pointer">
                <Link href="/privacy-policy">Privacy Policy</Link>
                <span className=" ml-2">|</span>
              </li>
              <li className="cursor-pointer">
                <Link href="/terms-and-conditions">Terms & Conditions</Link>
              </li>
            </ul>
          </div>
        </Container>
      </footer>
    </>
  );
}
