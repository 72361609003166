import axios from 'axios';
import { xml2json } from 'xml-js';

import REQUEST from 'app/helpers/http.service';

export const GetParagraph = item => {
  let indexStartParagraph = item?.indexOf('<p>');
  let indexEndParagraph = item?.indexOf('</p>', indexStartParagraph);
  let paragraph = item?.slice(indexStartParagraph + 3, indexEndParagraph);
  let indexEndStyle = paragraph?.indexOf('>');
  paragraph = paragraph?.slice(indexEndStyle + 1);
  if (paragraph?.length < 20) {
    indexStartParagraph = item?.indexOf('<p>', indexEndParagraph);
    indexEndParagraph = item?.indexOf('</p>', indexStartParagraph);
    paragraph = item?.slice(indexStartParagraph + 3, indexEndParagraph);
    indexEndStyle = paragraph?.indexOf('>');
    paragraph = paragraph?.slice(indexEndStyle + 1);
  }
  return paragraph;
};
export const createMarkup = content => {
  let addedNewLine = content?.replace(/(?:\r\n|\r|\n)/g, '<br />');
  addedNewLine = addedNewLine?.replaceAll(
    '/uploads/',
    `${process.env.NEXT_PUBLIC_API_URL}/uploads/`
  );
  return { __html: addedNewLine };
};

export const getLocaleCountry = async () => {
  const response2 = await axios.get('https://ipinfo.io/');
  let arr = response2?.data?.country;
  return { country: arr };
};

export const isUserInAllowedRegion = async () => {
  const allowedCountries = ['US', 'USA', 'CA', 'CAN'];

  try {
    // Fetch geolocation data
    const response = await fetch('https://ipapi.co/json/');
    const data = await response.json();

    // Check if the user's country is allowed
    return allowedCountries.includes(data.country_code);
  } catch (error) {
    console.error('Error fetching geolocation data:', error);
    return false; // Default to disallowed if there's an error
  }
};

export const JobdivaEmployers = async (from, to) => {
  try {
    const response = await axios.get(
      `https://www1.jobdiva.com/employers/connect/listofportaljobs.jsp?a=1pjdnwfgnlc4rm6chh038dyd6ozlh204dfyygr32n0q5fztfdwrdkwgwgg4c7350&from=${from}&to=${to}&fulldesc=1&includeuseremail=1`
    );
    return JSON.parse(xml2json(response.data, { compact: true, spaces: 4 }));
  } catch (error) {
    return console.error(error, 'job-divA-error');
  }
};

export const CalcDiffHours = (dateToken, actualDate) => {
  let diff = (actualDate.getTime() - dateToken.getTime()) / 1000;
  diff /= 3600;
  return Math.abs(Math.round(diff));
};
// export const download = (url) => {
//   if (url) {
//     const link = document.createElement("a");
//     link.target = "_blank";
//     link.href = url;
//     link.setAttribute("download", url);
//     document.body.appendChild(link);
//     link.click();
//   }
// };
export const download = url => {
  if (url) {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const pdfFileName = url.split('/').pop();
        const urlnew = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urlnew;
        link.download = pdfFileName;
        link.style.display = 'none';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(urlnew);
      })
      .catch(error => {
        console.error('Error downloading the file:', error);
      });
  }
};

export const zohoLeadApi = async data => {
  try {
    if ((data?.firstName || data?.lastName) && (data?.email || data?.mobile)) {
      const response = await axios({
        method: 'POST',
        url: `${process.env.NEXT_PUBLIC_ZOHO}save/lead`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          data: [
            {
              Company: data?.organization || 'NA',
              Last_Name: data?.lastName || 'NA',
              First_Name: data?.firstName,
              Email: data?.email || 'NA',
              State: data?.state || 'NA',
              City: data?.city || 'NA',
              Lead_Source: 'Inbound/Chat',
              Phone: data?.mobile || 'NA',
              Description: 'lead',
            },
          ],
          trigger: ['approval', 'workflow', 'blueprint'],
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const subscribeEmail = async data => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_ZOHO}send-subscribe-email`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        userEmail: data?.email,
        phone: data?.mobile,
        name: data?.name,
        org: data?.organization,
        message: data?.message,
        websitePage: '',
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getPages = async api => {
  let page = 1;
  let limit = 20;
  let contentPages = [];
  let total = 0;

  do {
    const [res] = await Promise.all([
      REQUEST({
        method: 'GET',
        url: `${api}?pagination[page]=${page}&pagination[pageSize]=${limit}`,
      }),
    ]);

    contentPages = [...contentPages, ...(res?.data?.data || [])];

    if (res?.data?.meta?.pagination?.total) {
      total = res?.data?.meta?.pagination?.total;
      page = page + 1;
    } else {
      break;
    }
  } while (total > contentPages.length);

  // Deduplicate slugs
  const uniqueContentPages = Array.from(
    new Map(contentPages.map(item => [item.attributes.slug, item])).values()
  );

  const paths = uniqueContentPages.map(c => ({
    params: { slug: c.attributes.slug },
  }));

  return {
    paths,
    fallback: false,
  };
};

export const changeTimeZone = (date, timeZone) => {
  if (typeof date === 'string') {
    return new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone,
      })
    );
  }

  return new Date(
    date?.toLocaleString('en-US', {
      timeZone,
    })
  );
};

export const generateFaqSchemas = array => {
  const faqSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: array?.map(item => ({
      '@type': 'Question',
      name: item?.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: item?.answer,
      },
    })),
  };
  return faqSchema;
};
export const generateServiceSchemas = array => {
  const faqSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: array?.map(item => ({
      '@type': 'Question',
      name: item?.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: item?.descriptionEditor,
      },
    })),
  };
  return faqSchema;
};

export const generateAggregateRating = llm_developer_data => {
  const aggregateRating = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: llm_developer_data?.metaTitle,
    image: llm_developer_data?.metaImage?.data?.attributes?.url,
    description: llm_developer_data?.metaDescription,
    brand: {
      '@type': 'Brand',
      name: 'vlinkinfo.com',
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.9',
      bestRating: '5',
      worstRating: '1',
      reviewCount: '2775',
    },
  };
  return aggregateRating;
};

export const getUserLocationData = timeZoneCityToCountry => {
  let userCity = null;
  let userCountry = null;
  let userTimeZone = null;

  if (typeof Intl !== 'undefined') {
    userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const tzArr = userTimeZone.split('/');
    userCity = tzArr[tzArr.length - 1];

    // Map through timeZoneCityToCountry array to find the country for the city
    timeZoneCityToCountry.forEach(coun => {
      if (coun[userCity]) {
        userCountry = coun[userCity];
      }
    });
  }

  return {
    userCity,
    userCountry,
    userTimeZone,
  };
};

export const getLocaleIP = async () => {
  try {
    const response = await axios.get('https://ipinfo.io/json');
    const ip = response?.data?.ip;
    return { ip };
  } catch (error) {
    console.error('Error fetching IP:', error);
    return { ip: null };
  }
};
