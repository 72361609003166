import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { focusOn } from '@cloudinary/url-gen/qualifiers/gravity';
import { FocusOn } from '@cloudinary/url-gen/qualifiers/focusOn';

import useResponsive from 'app/Hooks/useResponsive';

import cloudinaryMapUrls from '../../../public/cloudinaryLinks.json';

const baseUrl = process.env.NEXT_PUBLIC_API_URL;
const strapiBaseUrl = process.env.NEXT_PUBLIC_API_URL;

function extractPathFromLink(link) {
  if (link.startsWith(baseUrl)) {
    return link.substring(baseUrl.length);
  }
  return link;
}

function extractFileNameFromURL(url) {
  const cloudinaryBaseUrl = process.env.NEXT_PUBLIC_CLOUDINARY_BASE_URL;
  return url.replace(cloudinaryBaseUrl, '');
}

const CloudinaryImage = ({
  className,
  alt,
  backendImgUrl,
  style,
  type,
  width: propWidth,
  height: propHeight,
}) => {
  const altText = alt ?? 'image';

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
    },
  });

  const { isTablet, isDesktop } = useResponsive();

  // Use width and height from props if provided
  let width;
  let height;

  // If width is not provided, calculate it based on type and device
  if (type === 'icon') {
    width = 140;
  } else if (type === 'smallimg') {
    width = 480;
  } else if (isTablet) {
    width = 991;
  } else if (isDesktop) {
    width = 1280;
  } else {
    width = 480;
  }

  // If height is not provided, calculate it based on a default aspect ratio
  if (!height) {
    const aspectRatio = 16 / 9; // Default aspect ratio
    height = Math.round(width / aspectRatio);
  }

  let myImage;

  if (backendImgUrl?.includes('https://res.cloudinary.com')) {
    const imageName = backendImgUrl.substring(
      backendImgUrl.lastIndexOf('/') + 1
    );

    myImage = cld
      .image(imageName)
      .resize(fill().width(width).gravity(focusOn(FocusOn.faces())))
      .addTransformation('c_scale,q_auto,f_auto,fl_lossy');

    return (
      <AdvancedImage
        className={className}
        cldImg={myImage}
        alt={altText}
        style={style}
        type={type}
        width={width}
        height={height}
        loading="lazy"
      />
    );
  }

  const link = extractPathFromLink(backendImgUrl);
  const cloudinaryUrl = cloudinaryMapUrls[link];

  if (!cloudinaryUrl) {
    return (
      <img
        src={strapiBaseUrl + link}
        className={className}
        alt={altText}
        style={style}
        width={width}
        height={height}
      />
    );
  }

  const getPublicId = extractFileNameFromURL(cloudinaryUrl);

  myImage = cld
    .image(getPublicId)
    .resize(fill().width(width).gravity(focusOn(FocusOn.faces())))
    .addTransformation('c_scale,q_auto,f_auto,fl_lossy');

  return (
    <AdvancedImage
      className={className}
      cldImg={myImage}
      alt={altText}
      style={style}
      type={type}
      width={propWidth || width}
      height={propHeight || height}
      loading="lazy"
    />
  );
};

export default CloudinaryImage;
