import dynamic from 'next/dynamic';
import { useState } from 'react';

const HireScheduleTalent = dynamic(
  () => import('app/components/common/HireScheduleTalent')
);

import NavA from './Header/NavbarDesktop';
function Header() {
  const [modalScheduleCall, setModalScheduleCall] = useState(false);
  return (
    <div>
      <NavA setModalCall={setModalScheduleCall} />
      {modalScheduleCall && (
        <HireScheduleTalent
          ButtonText={modalScheduleCall}
          setIsOpen={setModalScheduleCall}
        />
      )}
    </div>
  );
}

export default Header;
