import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Link from 'next/link';

import staticData from '../../../public/staticData.json';
import CloudinaryImage from '../../components/common/CloudinaryImage';

import './header.css';

export default function Navbar({ setModalCall = false }) {
  const router = useRouter();
  const path = '/' + router.pathname.split('/')[1] ?? '';

  const [open, setOpen] = useState({
    menuName: '',
    isOpen: false,
  });

  const headerData = staticData?.header?.attributes?.menu;

  const headerDataSidebar = headerData?.menu_sidebar;
  function organizeData(jsonData) {
    const nestedData = {};

    const categorizedSubMenus = {};

    jsonData.menu_name.forEach(menu => {
      // Handle menu_name
      const menuName = menu.menu_name;
      if (!nestedData[menuName]) {
        nestedData[menuName] = {
          menu_name: menu.menu_name,
          menu_link: menu.menu_link,
          title: menu.title,
          desc: menu.desc,
          image: menu.image,
          sub_menus: [],
        };
      }

      // Handle sub_menu
      if (menu.sub_menu) {
        const subMenuObj = {
          sub_menu_name: menu.sub_menu,
          sub_menu_link: menu.sub_link,
          sub_sub_menus: [],
        };

        // Handle sub_sub_menu
        if (menu.sub_sub_menu) {
          subMenuObj.sub_sub_menus.push({
            sub_sub_menu_name: menu.sub_sub_menu,
            sub_sub_menu_link: menu.sub_sub_link,
          });
        }

        if (categorizedSubMenus[menu.sub_menu]) {
          // If the submenu already exists, add the sub_sub_menus to the existing array
          categorizedSubMenus[menu.sub_menu].sub_sub_menus.push(
            ...subMenuObj.sub_sub_menus
          );
        } else {
          // If the submenu doesn't exist, create a new entry in the categorizedSubMenus object
          categorizedSubMenus[menu.sub_menu] = subMenuObj;
          nestedData[menuName].sub_menus.push(subMenuObj);
        }
      }
    });

    return nestedData;
  }

  const combinedNestedData = organizeData(headerData);

  const menuEntries = Object.entries(combinedNestedData);

  const [mobile, setMobile] = useState(false);

  const handleToggle = (event, name) => {
    const isMobile = window.innerWidth <= 1024;
    if (isMobile) {
      if (name === open?.menuName) {
        setOpen({ ...open, isOpen: !open?.isOpen });
      } else {
        setOpen({ menuName: name, isOpen: true });
      }
    } else {
      const liElement = document.querySelectorAll(`.topmenu`);
      event.currentTarget.classList.remove('group');
      setTimeout(() => {
        liElement.forEach(elm => {
          if (!elm.classList.contains('group')) {
            elm.classList.add('group');
          }
        });
      }, 3000);
    }
  };
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 140);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navbarClass = isScrolled
    ? 'navbar bg-[#FFFFFF_!important] z-50 shadow-[0px_0px_4px_0px_#00000045] sticky top-0  scrolled'
    : 'navbar bg-[#FFFFFF_!important] z-50 shadow-[0px_0px_4px_0px_#00000045] sticky top-0 ';

  return (
    <nav
      className={`navbar bg-[#FFFFFFAB] z-50 shadow-[0px_0px_4px_0px_#00000045] sticky top-0 ${navbarClass}`}
    >
      <div className={`container flex flex-row items-center justify-between`}>
        <div className="flex flex-row items-center py-2.5 pr-3">
          <button
            type="button"
            className="p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 lg:hidden"
            onClick={() => setMobile(!mobile)}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          <Link href="/" className="ml-1">
            <Image
              src="/img/navbar-logo.svg"
              className="h-17 md:w-20 w-16 sm:h-17"
              alt="Vlink Logo"
              width={159}
              height={15}
            />
          </Link>
        </div>
        <div className="flex flex-row flex-end gap-5 static">
          <div
            id="navbar-cta"
            className={`items-stretch justify-between ${
              mobile
                ? 'border-b margin-[#ccc] pb-1 transition-[all_0.3s_ease_0s] lg:static absolute lg:top-[unset] top-[68px] lg:h-[auto] h-[calc(100vh - 70px)] lg:overflow-x-visible overflow-x-hidden lg:overflow-y-visible overflow-y-auto lg:border-b-[0px] border-t-[1px_solid_rgb(238, 238, 238)] justify-center  lg:w-auto w-[100%] lg:z-[unset] z-[1000] lg:left-[unset] left-[0px_!important] lg:bg-[trasnparent]'
                : ''
            } w-full lg:flex md:w-auto`}
          >
            <div
              className={`items-stretch justify-between  w-full lg:flex md:w-auto `}
            >
              <ul
                id="customScrollbar-none"
                className={`flex items-center ${mobile ? 'justify-start' : ''}`}
                style={mobile ? { overflowX: 'auto' } : {}}
              >
                {menuEntries.map(([menuName, menuData], key) => (
                  <li
                    className={`group topmenu ${
                      open?.menuName === menuName && open.isOpen
                        ? 'openmenu'
                        : 'closemenu'
                    }`}
                    key={key}
                    onClick={e => handleToggle(e, menuName, menuData)}
                  >
                    <div className="flex items-center lg:justify-normal justify-between lg:mx-3">
                      <Link
                        className={`${
                          menuData?.menu_link === path ? 'active' : ''
                        } custom_font text-[#000000] font-[500] text-xs lg:text-md rounded-t-lg border-b-2
                         border-transparent pb-5 pt-5 lg:hover:border-[#0050D5_!important] lg:hover:text-[#0050D5_!important] flex items-center lg:justify-normal justify-between`}
                        href={menuData?.menu_link || '#'}
                        onClick={() => setMobile(!mobile)}
                      >
                        {menuName}
                        {menuData?.sub_menus.length !== 0 ? (
                          <svg
                            className="svg-inline--fa fa-angle-down pl-2 w-[20px] h-[20px] lg:block hidden"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentColor"
                              d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                            ></path>
                          </svg>
                        ) : (
                          ''
                        )}
                      </Link>
                      {menuData?.sub_menus.length !== 0 ? (
                        <svg
                          className="svg-inline--fa fa-angle-down pl-2 w-[20px] h-[10px] lg:hidden"
                          viewBox="0 0 448 512"
                        >
                          <path
                            fill="currentColor"
                            d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                          ></path>
                        </svg>
                      ) : (
                        ''
                      )}
                    </div>

                    {menuData?.sub_menus.length !== 0 ? (
                      <header className="max-w-[1600px] border-t-[1px] border-[#bab7b7] mx-auto transition-[all_.3s_ease-in] hidden lg:opacity-0 lg:group-hover:opacity-100 lg:group-hover:block lg:absolute left-0 right-0 w-[100%] bg-[#fff] lg:shadow-lg">
                        <div
                          className={`megadiv flex flex-row ${
                            menuData?.sub_menus.some(
                              client => client.sub_sub_menus.length !== 0
                            )
                              ? 'uuu'
                              : 'www'
                          }`}
                        >
                          <div
                            className={`${
                              menuData.title !== null
                                ? `lg:w-[50%] w-[100%] bg-[#F9F9F9] md:p-8 p-4 lg:mb-0 mb-4`
                                : ``
                            } `}
                          >
                            <ul role="list">
                              {menuData.title !== null ? (
                                <div className="w-[100%]">
                                  <h4 className="mb-2  lg:text-[40px] md:text-[30px] text-[20px] text-[#000000] font-[600] inline-block">
                                    {menuData?.title}
                                  </h4>

                                  <p className=" text-[#000000] lg:text-[20px] text-[16px] font-[400] mb-4">
                                    {menuData?.desc}
                                  </p>
                                </div>
                              ) : (
                                headerDataSidebar.map(item => (
                                  <span key={item?.id}></span>
                                ))
                              )}
                            </ul>
                          </div>
                          <div
                            className={`inline-flex flex-wrap lg:p-8 custom_left ${
                              menuData?.sub_menus.some(
                                client => client.sub_sub_menus.length === 0
                              )
                                ? 'add_w'
                                : 'hidden'
                            }`}
                          >
                            <ul className="pb-8">
                              {menuData?.sub_menus.map((subMenu, key) => (
                                <div key={key}>
                                  {subMenu?.sub_menu_link === null ? (
                                    ''
                                  ) : (
                                    <li
                                      className={`submenu py-2 text-[#131313] font-[500] text-[18px]`}
                                      key={subMenu?.sub_menu_name}
                                    >
                                      <Link
                                        href={`${
                                          subMenu?.sub_menu_link || '#'
                                        }`}
                                        onClick={() => setMobile(!mobile)}
                                        className="text-[#131313_!important] hover:text-[#0050D5_!important]"
                                      >
                                        {subMenu?.sub_menu_name}
                                      </Link>
                                    </li>
                                  )}
                                </div>
                              ))}
                            </ul>
                          </div>
                          <div
                            className={`flex lg:p-8 p-0 custom_response
                            ${menuData?.menu_name === 'Hire Developers' && menuData?.sub_menus.length > 5 && 'grid grid-cols-5'}
                            ${
                              menuData?.sub_menus.some(
                                client => client.sub_sub_menus.length !== 0
                              )
                                ? 'add_u'
                                : 'hidden'
                            }`}
                          >
                            {menuData?.sub_menus.map((subMenu, key) => (
                              <div
                                key={key}
                                className={
                                  subMenu?.sub_menu_link === null ||
                                  subMenu?.sub_sub_menus.length !== 0
                                    ? 'flex-1'
                                    : ''
                                }
                              >
                                {subMenu?.sub_menu_link === null ||
                                subMenu?.sub_sub_menus.length !== 0 ? (
                                  <div className={`submenu lg:pl-4`}>
                                    <li className="border-b-[#868686]-300 border-b-[2px] inline-block py-2 mb-2 text-[#2A2A2A] font-[700] text-[18px]  ">
                                      {subMenu?.sub_menu_link ? (
                                        <Link
                                          href={`${
                                            subMenu?.sub_menu_link || '#'
                                          }`}
                                          className="text-[#131313_!important]"
                                        >
                                          {subMenu?.sub_menu_name}
                                        </Link>
                                      ) : (
                                        subMenu?.sub_menu_name
                                      )}
                                    </li>
                                    <ul className="pb-8">
                                      {subMenu.sub_sub_menus.map(
                                        (subSubMenu, key) => (
                                          <li
                                            className={`three py-2 text-[#131313] font-[500] text-[16px]`}
                                            key={key}
                                          >
                                            <Link
                                              href={`${subSubMenu.sub_sub_menu_link}`}
                                              onClick={() => setMobile(!mobile)}
                                              className="text-[#131313_!important] hover:text-[#0050D5_!important]"
                                            >
                                              {subSubMenu.sub_sub_menu_name}
                                            </Link>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            ))}
                          </div>
                          <div
                            className={`${
                              menuData?.menu_name !== 'Services'
                                ? ``
                                : `w-[40%] lg:block hidden megacol lg:pl-10 pb-10 lg:pr-10 pt-10 bg-[#F9F9F9]`
                            }`}
                          >
                            <ul role="list">
                              {menuData?.menu_name === 'Services' &&
                                headerDataSidebar.map((item, key) => (
                                  <li
                                    className="group/item flex items-center justify-between py-3"
                                    key={key}
                                  >
                                    <div className="flex-shrink-0 pr-2 w-12 h-12">
                                      <CloudinaryImage
                                        type="icon"
                                        backendImgUrl={
                                          item.image.data.attributes.url
                                        }
                                        alt={
                                          item.image.data.attributes
                                            .alternativeText
                                        }
                                        className={'h-[auto] max-h-full'}
                                      />
                                    </div>
                                    <div className="w-full text-sm leading-6">
                                      <p className="mb-0 font-[400]  text-[16px] text-[#000000]">
                                        {item?.year}
                                      </p>
                                      <p className="text-[#000000] text-[16px]">
                                        {item?.text}
                                      </p>
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      </header>
                    ) : (
                      ''
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="flex items-center">
          {router.asPath.startsWith('/webinars/') ? (
            ''
          ) : (
            <button
              id="HireTopTelent"
              type="button"
              onClick={() => setModalCall('Talk to Our Experts')}
              className={`wave-effects text-[#fff] bg-[#0050D5] border-[#0050D5] hover:bg-[#0050D5] shadow-[10px_10px_40px_0px_#FFEBD4] hover:text-[#fff] mt-[0px] border group h-min text-center font-medium focus:z-10 relative rounded overflow-hidden text-center inline-block py-2 px-4 md:px-7`}
            >
              {'Talk to Our Experts'}
            </button>
          )}
        </div>
      </div>
    </nav>
  );
}
