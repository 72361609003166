const MailOutlineIcon = ({ width = 19, height = 19, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.16666 15.8334C2.73124 15.8334 2.35863 15.6785 2.04883 15.3687C1.73849 15.0584 1.58333 14.6855 1.58333 14.2501V4.75008C1.58333 4.31466 1.73849 3.94205 2.04883 3.63225C2.35863 3.32191 2.73124 3.16675 3.16666 3.16675H15.8333C16.2687 3.16675 16.6416 3.32191 16.952 3.63225C17.2618 3.94205 17.4167 4.31466 17.4167 4.75008V14.2501C17.4167 14.6855 17.2618 15.0584 16.952 15.3687C16.6416 15.6785 16.2687 15.8334 15.8333 15.8334H3.16666ZM9.49999 10.2917L3.16666 6.33341V14.2501H15.8333V6.33341L9.49999 10.2917ZM9.49999 8.70841L15.8333 4.75008H3.16666L9.49999 8.70841ZM3.16666 6.33341V4.75008V14.2501V6.33341Z"
        fill="currentColor"
        fillOpacity="0.44"
      />
    </svg>
  );
};
export default MailOutlineIcon;
