let trackingData = {
  userAgent: typeof window !== 'undefined' ? navigator.userAgent : 'unknown',
  entryTime: new Date().toISOString(),
  referrer:
    typeof document !== 'undefined' ? document.referrer || 'Direct' : 'Unknown',
  WebPagesVisited: [],
};

export const trackEvent = url => {
  if (!trackingData.WebPagesVisited.includes(url)) {
    trackingData.WebPagesVisited.push(url);
  }
};

export const getTrackingData = () => {
  return {
    ...trackingData,
    exitTime: new Date().toISOString(), // Capture exit time when form is submitted
  };
};
