import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { Provider } from 'react-redux';
import { Open_Sans, Roboto } from 'next/font/google';

import { trackEvent } from 'app/webtrack/tracking';
import ErrorBoundary from 'app/components/common/ErrorBoundary';
import Layout from 'app/layout';

import { store } from 'store/store';

import '@fontsource/dm-sans/100.css';
import '@fontsource/dm-sans/200.css';
import '@fontsource/dm-sans/300.css';
import '@fontsource/dm-sans/400.css';
import '@fontsource/dm-sans/500.css';
import '@fontsource/dm-sans/700.css';
import '@fontsource/roboto/100.css'; /* Thin */
import '@fontsource/roboto/300.css'; /* Light */
import '@fontsource/roboto/400.css'; /* Regular */
import '@fontsource/roboto/500.css'; /* Medium */
import '@fontsource/roboto/700.css'; /* Bold */
import '@fontsource/roboto/900.css'; /* Black */

const openSans = Open_Sans({
  subsets: ['latin'],
  weight: ['300', '400', '500', '600', '700', '800'],
  style: ['normal', 'italic'],
  display: 'swap',
  variable: '--font-open-sans',
});
const roboto = Roboto({
  subsets: ['latin'],
  weight: ['100', '300', '400', '500', '700', '900'],
  style: ['normal', 'italic'],
  display: 'swap',
  variable: '--font-roboto',
});

const SupportChat = dynamic(
  () => import('app/components/support-chat/SupportChat'),
  {
    ssr: false,
  }
);

function MyApp({ Component, pageProps }) {
  const [showSupportChat, setShowSupportChat] = useState(false);
  const router = useRouter();

  useEffect(() => {
    // Delay loading by, for example, 5 seconds
    const timer = setTimeout(() => {
      setShowSupportChat(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleRouteChange = url => {
      trackEvent(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    handleRouteChange(window.location.pathname);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <div className={`${roboto.className} ${openSans.className}`}>
      <ErrorBoundary>
        <Provider store={store}>
          <Layout data={pageProps?.homePageData}>
            <Component {...pageProps} />
          </Layout>
        </Provider>
      </ErrorBoundary>
      {showSupportChat && <SupportChat />}
    </div>
  );
}

export default MyApp;
