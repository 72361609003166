import Link from 'next/link';

import Container from './Container';
import '../common/globallocation.css';

export default function GlobalLocation({ global_location }) {
  return (
    <div
      className="bg-[#15151D] xl:py-[55px] py-[35px] bg-cover bg-no-repeat bg-center"
      style={{
        backgroundImage: `url(https://res.cloudinary.com/dthpnue1d/image/upload/c_fill,g_faces,w_991/v1691470450/footer_texture_dd354ddcbc.png)`,
      }}
    >
      <Container>
        <div className="xl:mb-[34px] mb-[30px]">
          <div className="md:text-center text-left text-[#ffffff] font-[800] xl:text-[50px] md:text-[32px] text-[26px] title_hd leading-tight">
            {global_location?.title}
          </div>
        </div>
        <div className="grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 md:gap-y-14 gap-y-2">
          {global_location?.Address &&
            global_location?.Address?.map((location, index) => (
              <div
                key={location?.id}
                index={index}
                className={`location_border md:px-14 px-0 py-4  md:border-b-0 border-b-[1px] border-[#FFFFFF]`}
              >
                <div
                  className={`relative text-[#FFFFFF] ${location?.country === 'U.S.' && 'hover:text-[#0050d5]'} md:text-[24px] text-[18px] font-[700] leading-tight mb-2`}
                >
                  {location?.country === 'U.S.' ? (
                    <Link
                      href={`${
                        location?.country === 'U.S.' &&
                        `/mobile-application-development-company-in-usa`
                      }`}
                    >
                      {location?.country}
                    </Link>
                  ) : (
                    <span>{location?.country}</span>
                  )}
                </div>
                <div className="gap-3 flex">
                  {location?.address?.map((address, index) => (
                    <span
                      key={address?.id}
                      className={`${index + 1 !== location?.address?.length ? 'border-r border-[#D9D9D9] pr-3' : ''} md:text-[18px] text-[18px] text-[#FFFFFF] md:font-[400] font-[600]`}
                    >
                      {address?.address}
                    </span>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </Container>
    </div>
  );
}
