import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import ACTION_TYPE from 'store/action-type';
import 'styles/globals.css';
import 'styles/styles.css';

import LastCommit from '../../public/text.json';

import LayoutFooter from './Footer';
import Header from './Header';

function Layout({ data, children }) {
  const dispatch = useDispatch();

  const [mobileSidebarIsOpen, setMobileSidebarIsOpen] = useState(false);

  useEffect(() => {
    dispatch({
      type: ACTION_TYPE.TOAST,
      payload: toast,
    });
  }, []);

  return (
    <div className="max-w-[100vw] max-h-[100vh]">
      <Header
        logo={data?.section1?.logo}
        mobileSidebarIsOpen={mobileSidebarIsOpen}
        setMobileSidebarIsOpen={setMobileSidebarIsOpen}
      />
      <ToastContainer />
      {children}
      <div className={`seoId  ${LastCommit + process.env.GIT_HASH}`} />
      <LayoutFooter />
    </div>
  );
}
export default Layout;
