const ACTION_TYPE = {
  FETCH_HEADER: 'FETCH_HEADER',
  FETCH_FOOTER: 'FETCH_FOOTER',
  AWARDS: 'AWARDS',
  TRUSTED_BY_STRATUP: 'TRUSTED_BY_STRATUP',
  SUCCESS_STORY: 'SUCCESS_STORY',
  TESTIMONIALS: 'TESTIMONIALS',
  COMMON_BLOG: 'COMMON_BLOG',
  SUCCESS_NUMBER: 'SUCCESS_NUMBER',
  COMMON_NEWS: 'COMMON_NEWS',
  BLOG_CATEGORIES: 'BLOG_CATEGORIES',
  SELECTED_BLOG_CATEGORY: 'SELECTED_BLOG_CATEGORY',
  SELECTED_BLOG_NAME: 'SELECTED_BLOG_NAME',
  GET_COUNTRY: 'GET_COUNTRY',
  TOAST: 'TOAST',

  FETCH_HOME: 'FETCH_HOME',
  SERVICES: 'SERVICES',
  DEDICATED_TEAM: 'DEDICATED_TEAM',
  PRODUCT: 'PRODUCT',
  WEB_DEVELOPMENT: 'WEB_DEVELOPMENT',
  CUSTOM_MOBILE: 'CUSTOM_MOBILE',
  SOFTWARE_MAINTENANCE: 'SOFTWARE_MAINTENANCE',
  QA_SERVICE: 'QA_SERVICE',
  MANAGED_IT: 'MANAGED_IT',
  RE_ENGINEERING: 'RE_ENGINEERING',
  DEVOPS: 'DEVOPS',
  MACHINE_LEARNING: 'MACHINE_LEARNING',
  IOT: 'IOT',
  CLOUD_MANAGED: 'CLOUD_MANAGED',
  IT_CONSULTING: 'DEDICATED_TEAM',

  INDUSTRY: 'INDUSTRY',
  INDUSTRY_EDTECH: 'INDUSTRY_EDTECH',
  INDUSTRY_HEALTHCARE: 'INDUSTRY_HEALTHCARE',
  INDUSTRY_FINANCE: 'INDUSTRY_FINANCE',
  INDUSTRY_MANUFACTURING: 'INDUSTRY__MANUFACTURING',
  INDUSTRY_TELECOM: 'INDUSTRY_TELECOM',
  INDUSTRY_ECOMMERCE: 'INDUSTRY_ECOMMERCE',
  INDUSTRY_MEDIA: 'INDUSTRY_MEDIA',
  INDUSTRY_CUSTOMER: 'INDUSTRY_CUSTOMER',
  HIRE_DEV: 'HIRE_DEV',
  PYTOHON_DEV: 'PYTOHON_DEV',
  CASE_STUDY: 'CASE_STUDY',
  OUR_COMPONY: 'OUR_COMPONY',
  OUR_PROCESS: 'OUR_PROCESS',
  CONTACT_US: 'CONTACT_US',
  FAQ: 'FAQ',
  CSR: 'CSR',
  PODCAST: 'PODCAST',
  BLOG: 'BLOG',
  BROCHURE: 'BROCHURE',
  WHITEPAPER: 'WHITEPAPER',
  WEBINAR: 'WEBINAR',
  NEWSROOM: 'NEWSROOM',
  CARRER: 'CARRER',
  JOB_DETAIL: 'JOB_DETAIL',
  LCA_PAGE: 'LCA_PAGE',
};

export default ACTION_TYPE;
